<script setup lang="ts">
const $props = defineProps<{
	organisation: App.Enums.OrganisationEnum
}>()

const { organisationIdMaskOptions, birthdateMaskOptions } = useInputMasks()
const { closeLocally } = useDialog()

const form = useForm<App.Data.Account.CreateRiderData>({
	method: 'POST',
	url: route('account.store-rider'),
	fields: {
		organisation: $props.organisation,
		organisation_id: '',
		birthdate: '',
	},
	hooks: {
		success: () => {
			closeLocally()
		},
	},
})
</script>

					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<modal-base :title="t('app.add_your_organisation_account', { organisation: t('app.organisation.' + form.fields.organisation) })">
		<form class="space-y-5" @submit.prevent="form.submit">
			<form-input-wrapper :label="form.fields.organisation ? t(`app.organisation_id.${form.fields.organisation}`) : t('app.organisation_id.fallback')" for="organisation_id" :errors="form.errors.organisation_id">
				<form-input-text
					id="organisation_id"
					v-model="form.fields.organisation_id"
					v-maska:[organisationIdMaskOptions]
					required
					autofocus
				/>
			</form-input-wrapper>

			<form-input-wrapper :label="t('app.birthdate')" for="birthdate" :errors="form.errors.birthdate">
				<form-input-text
					id="birthdate"
					v-model="form.fields.birthdate"
					v-maska:[birthdateMaskOptions]
					placeholder="dd/mm/yyyy"
					required
				/>
			</form-input-wrapper>

			<div class="flex justify-end space-x-4">
				<button-base variant="secondary" type="button" @click="closeLocally">
					{{ t('app.cancel') }}
				</button-base>

				<button-base type="submit" :loading="form.processing">
					{{ t('app.save') }}
				</button-base>
			</div>
		</form>
	</modal-base>
</template>
