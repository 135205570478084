<script setup lang="ts">
const $props = defineProps<{
	rider: App.Data.Rider.RiderData
}>()

const { transponderMaskOptions } = useInputMasks()
const { closeLocally } = useDialog()

const form = useForm<App.Data.Rider.UpdateRiderData>({
	method: 'PATCH',
	url: route('riders.update', { rider: $props.rider.uuid }),
	reset: false,
	fields: {
		mylaps_transponder_20inch: $props.rider.mylaps_transponder_20inch,
		mylaps_transponder_cruiser: $props.rider.mylaps_transponder_cruiser,
	},
	hooks: {
		success: () => {
			closeLocally()
		},
	},
})
</script>

					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<modal-base :title="t('app.edit_rider', { rider: rider.full_name })">
		<form class="space-y-5" @submit.prevent="form.submit">
			<form-input-wrapper :label="t('app.mylaps_transponder_20inch')" for="mylaps_transponder_20inch" :errors="form.errors.mylaps_transponder_20inch" optional>
				<form-input-text
					id="mylaps_transponder_20inch"
					v-model="form.fields.mylaps_transponder_20inch"
					v-maska:[transponderMaskOptions]
					autofocus
				/>
			</form-input-wrapper>

			<form-input-wrapper :label="t('app.mylaps_transponder_cruiser')" for="mylaps_transponder_cruiser" :errors="form.errors.mylaps_transponder_cruiser" optional>
				<form-input-text
					id="mylaps_transponder_cruiser"
					v-model="form.fields.mylaps_transponder_cruiser"
					v-maska:[transponderMaskOptions]
				/>
			</form-input-wrapper>

			<div class="flex justify-end space-x-4">
				<button-base variant="secondary" type="button" @click="closeLocally">
					{{ t('app.cancel') }}
				</button-base>

				<button-base type="submit" :loading="form.processing">
					{{ t('app.save') }}
				</button-base>
			</div>
		</form>
	</modal-base>
</template>
