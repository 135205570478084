<script setup lang="ts">
const $props = defineProps<{
	status: number
}>()

const title = computed(() => t(`app.errors.${$props.status ?? 503}.title`))
const description = computed(() => t(`app.errors.${$props.status ?? 503}.description`))

useHead({
	title: () => title.value,
})
</script>

<template>
	<div class="grid min-h-full place-items-center bg-white px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
		<main class="mx-auto max-w-max">
			<section class="items-center sm:flex">
				<p class="text-4xl font-bold tracking-tight text-primary-300 sm:text-5xl" v-text="status" />
				<div class="sm:ml-6">
					<div class="sm:border-l sm:border-gray-200 sm:pl-6">
						<heading1>{{ title }}</heading1>
						<p class="mt-1 text-gray-500" v-text="description" />
					</div>
				</div>
			</section>

			<div class="mt-6 flex space-x-3 sm:mt-10 sm:border-l sm:border-transparent sm:pl-6 md:ml-28">
				<button-base :href="route('organizers.index')">
					{{ t('app.back_to_dashboard') }}
				</button-base>
			</div>
		</main>
	</div>
</template>
