<script setup lang="ts">
import { c2c } from 'vue-c2c'
import Confirm from '@/components/confirm.vue'

const $props = defineProps<{
	registration: App.Data.Registration.RegistrationData
	totalCategoryLines: number
	totalRegistrationFeeLines: number
	totalCategoryLinesPrice: string
	priceWithoutPaymentFee: string
	paymentOptions: App.Data.Registration.RegistrationPaymentOptionsData[]
	feeLines: {
		name: string
		total: string
		count: number
	}[]
}>()

defineLayoutProperties({
	pageTitle: t('app.registrations.finish.title', { registration: $props.registration.id }),
})

const form = useForm({
	method: 'POST',
	url: route('registrations.finish.store', { registration: $props.registration.uuid }),
	fields: {
		payment_method: '',
	},
})

const paymentFee = computed(() => {
	const paymentOption = $props.paymentOptions.find(
		(option) => option.payment_method === form.fields.payment_method,
	)

	return paymentOption ? paymentOption.fee : '0.00'
})

const totalPriceWithFees = computed(() => {
	const paymentOption = $props.paymentOptions.find(
		(option) => option.payment_method === form.fields.payment_method,
	)

	return paymentOption ? paymentOption.total_price_with_fees : '0.00'
})

const useConfirm = c2c(Confirm)
const { hide, toggle, visible, exposed } = useConfirm({
	title: t('app.registration_payment_method_bank_transfer_description_intro'),
	content: t('app.registration_payment_method_bank_transfer_description'),
	confirmText: t('app.proceed_to_payment'),
}, {
	emits: {
		onOk() {
			form.submit()
		},
		// @ts-ignore-next-line
		onCancel() {
			hide()
		},
	},
})

const paymentConfirm = () => {
	if (form.fields.payment_method === 'banktransfer') {
		toggle()

		return false
	}

	return true
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<page-container>
		<message-wrapper>
			<message-error v-if="form.errors.payment_method" v-text="form.errors.payment_method" />
		</message-wrapper>

		<message-warning v-if="paymentOptions.length === 0">
			{{ t('app.no_payment_options_available') }}
		</message-warning>

		<form @submit.prevent="form.submit">
			<table-base>
				<template #head>
					<table-row>
						<table-column class="pl-0" />
						<table-column>
							{{ t('app.quantity') }}
						</table-column>
						<table-column>
							{{ t('app.subtotal') }}
						</table-column>
					</table-row>
				</template>
				<!--				<table-row>-->
				<!--					<table-column class="pl-0">-->
				<!--						{{ t('app.payment_method') }}-->
				<!--					</table-column>-->
				<!--					<table-column>-->
				<!--						<form-select v-model="form.fields.payment_method">-->
				<!--							<option value="">-->
				<!--								{{ t('app.select_payment_method') }}-->
				<!--							</option>-->
				<!--							<option v-for="payment_option in paymentOptions" :key="payment_option.payment_method" :value="payment_option.payment_method">-->
				<!--								{{ t('app.registration_payment_method_' + payment_option.payment_method) }}-->
				<!--							</option>-->
				<!--						</form-select>-->
				<!--					</table-column>-->
				<!--				</table-row>-->
				<!--				<table-row>-->
				<!--					<table-column class="pl-0">-->
				<!--						{{ t('app.total_price_without_fees') }}-->
				<!--					</table-column>-->
				<!--					<table-column>&euro; {{ registration.total_price }}</table-column>-->
				<!--				</table-row>-->
				<table-row v-if="totalCategoryLines > 0">
					<table-column class="pl-0">
						{{ t('app.entries') }}
					</table-column>
					<table-column>{{ totalCategoryLines }}</table-column>
					<table-column>
						<price>{{ totalCategoryLinesPrice }}</price>
					</table-column>
				</table-row>
				<template v-if="feeLines">
					<table-row v-for="(feeLine, index) in feeLines" :key="'feeLine_' + index">
						<table-column class="pl-0">
							{{ feeLine.name }}
						</table-column>
						<table-column>{{ feeLine.count }}</table-column>
						<table-column>
							<price>{{ feeLine.total }}</price>
						</table-column>
					</table-row>
				</template>
				<table-row>
					<table-column class="pl-0">
						{{ t('app.total_registration_fee') }}
					</table-column>
					<table-column>{{ totalRegistrationFeeLines }}</table-column>
					<table-column>
						<price>{{ registration.total_registration_fee }}</price>
					</table-column>
				</table-row>
				<template v-if="registration.additional_fees">
					<table-row v-for="(additional_fee, index) in registration.additional_fees" :key="index">
						<table-column class="pl-0">
							{{ additional_fee.description }}
							<small v-if="additional_fee.ioc_code" class="-mt-1.5 block text-gray-500">
								{{ t('app.for_x_riders_from', { count: additional_fee.count, country: t('countries.ioc.' + additional_fee.ioc_code.toUpperCase()) }) }}
							</small>
						</table-column>
						<table-column>{{ additional_fee.count }}</table-column>
						<table-column>
							<price>{{ additional_fee.price }}</price>
						</table-column>
					</table-row>
				</template>
				<table-row v-if="form.fields.payment_method">
					<table-column class="pl-0">
						{{ t('app.total_payment_fee') }}
					</table-column>
					<table-column>1</table-column>
					<table-column>
						<price>{{ paymentFee }}</price>
					</table-column>
				</table-row>
			</table-base>

			<div class="flex flex-col">
				<div class="mb-2 text-right text-sm">
					<template v-if="form.fields.payment_method">
						{{ t('app.total') }}: &euro; {{ totalPriceWithFees }}
					</template>
					<template v-else>
						{{ t('app.subtotal') }}: &euro; {{ priceWithoutPaymentFee }}
					</template>
				</div>
				<div class="ml-auto flex items-end space-x-2">
					<headless-listbox v-model="form.fields.payment_method">
						<div class="relative">
							<headless-listbox-button
								class="relative h-[40px] w-full min-w-[200px] cursor-pointer border border-black bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-primary-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-300 sm:text-sm"
							>
								<span v-if="form.fields.payment_method === ''" class="block truncate">{{ t('app.select_payment_method') }}</span>
								<span v-else class="flex items-center gap-2 truncate">
									<img
										:src="`/images/paymentmethods/` + form.fields.payment_method + `.svg`"
										class="size-6"
									/>
									{{ t('app.registration_payment_method_' + form.fields.payment_method) }}
								</span>
								<span
									class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
								>
									<i-gg-chevron-down
										class="h-5 w-5 text-black"
										aria-hidden="true"
									/>
								</span>
							</headless-listbox-button>

							<transition
								leave-active-class="transition duration-100 ease-in"
								leave-from-class="opacity-100"
								leave-to-class="opacity-0"
							>
								<headless-listbox-options
									class="absolute mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
								>
									<headless-listbox-option
										v-for="payment_option in paymentOptions"
										v-slot="{ active, selected }"
										:key="payment_option.payment_method"
										:value="payment_option.payment_method"
										as="template"
									>
										<li
											:class="[
												active ? 'bg-primary-100 text-primary-900' : 'text-gray-900',
												'relative cursor-pointer select-none px-4 py-2',
											]"
										>
											<span
												:class="[
													selected ? 'font-medium text-primary' : 'font-normal',
													'flex items-center gap-2 truncate',
												]"
											>
												<img
													:src="`/images/paymentmethods/` + payment_option.payment_method + `.svg`"
													class="size-7"
												/>
												{{ t('app.registration_payment_method_' + payment_option.payment_method) }}
											</span>
										</li>
									</headless-listbox-option>
								</headless-listbox-options>
							</transition>
						</div>
					</headless-listbox>

					<!--					<form-select v-model="form.fields.payment_method" class="min-w-[200px]">-->
					<!--						<option value="">-->
					<!--							{{ t('app.select_payment_method') }}-->
					<!--						</option>-->
					<!--						<option v-for="payment_option in paymentOptions" :key="payment_option.payment_method" :value="payment_option.payment_method">-->
					<!--							{{ t('app.registration_payment_method_' + payment_option.payment_method) }}-->
					<!--						</option>-->
					<!--					</form-select>-->
					<button-base class="min-w-[165px]" type="submit" :onclick="paymentConfirm" :loading="form.processing">
						{{ t('app.proceed_to_payment') }}
					</button-base>
				</div>
			</div>
		</form>
	</page-container>
</template>
