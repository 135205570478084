<script setup lang="ts">
const props = defineProps<{
	label: string
	for?: string
	optional?: boolean
	errors?: string
}>()
</script>

<template>
	<div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
		<label class="block text-sm font-medium leading-6 text-gray-900 sm:pt-2" :for="props.for">
			{{ props.label }}
			<span v-if="props.optional" class="text-gray-500"> ({{ t('app.optional') }})</span>
		</label>
		<div class="mt-2 sm:col-span-2 sm:mt-0">
			<slot />

			<div v-auto-animate>
				<span
					v-if="props.errors"
					class="mt-2 text-sm text-red-600"
					v-text="props.errors"
				/>
			</div>
		</div>
	</div>
</template>
