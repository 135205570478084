<script setup lang="ts">
import isEmpty from 'lodash/isEmpty'
import { c2c } from 'vue-c2c'
import Confirm from '@/components/confirm.vue'

const $props = defineProps<{
	registration: App.Data.Registration.RegistrationData & {
		event: App.Data.EventData
		registration_lines: App.Data.Registration.RegistrationLineData[]
	}
}>()

defineLayoutProperties({
	pageTitle: t('app.registration_title', { number: $props.registration.id, event: $props.registration.event?.name }),
})

const { user } = useUser()
const paymentRequired = computed(() => $props.registration.event?.payment_required)
const errors = useProperty<{ [key: string | number]: string }>('errors')

const detachRegistrationLine = ref<App.Data.Registration.RegistrationLineData | null>(null)
const useConfirm = c2c(Confirm)
const { hide, toggle, visible, exposed } = useConfirm({
	title: t('app.detach'),
	content: t('app.rider_detach_confirm'),
	confirmText: t('app.rider_cancel_confirm_confirm_button'),
	cancelText: t('app.rider_cancel_confirm_close_button'),
}, {
	emits: {
		onOk() {
			if (!detachRegistrationLine.value) {
				return
			}

			router.delete(route('registrationLines.destroy', { registrationLine: detachRegistrationLine.value.uuid }), {
				preserveState: true,
				preserveScroll: true,
			})

			detachRegistrationLine.value = null
		},
		// @ts-ignore-next-line
		onCancel() {
			detachRegistrationLine.value = null
		},
	},
})
watch(detachRegistrationLine, (newValue) => {
	if (newValue) {
		toggle()
	} else {
		hide()
	}
})

const registrationLines = useProperty<App.Data.Registration.RegistrationLineData[]>('registration.registration_lines')
const { searchQuery, filteredRegistrationLines } = useRegistrationLineSearch(registrationLines)
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<page-container class="space-y-8">
		<div class="flex flex-col justify-between space-y-4 md:flex-row md:space-x-4 md:space-y-0">
			<div v-if="registration.event && can(registration.event, 'register')" class="flex space-x-4">
				<button-base :href="route('registrations.registrationLines.create', { registration: registration.uuid })">
					{{ t('app.add_rider') }}
				</button-base>

				<button-base v-if="user?.type === 'entity'" :href="route('events.registrations.bulk-create', { event: registration.event.uuid })">
					<i-gg-import class="h-4 w-4" aria-hidden="true" />
					{{ t('app.reimport_file') }}
				</button-base>
			</div>

			<div v-if="registration.registration_lines.length > 2" class="flex flex-col justify-end gap-4 md:flex-row">
				<table class="text-sm text-gray-500">
					<tr>
						<td class="pr-4 font-bold">
							{{ t('app.total_entries') }}
						</td>
						<td>{{ registration.registration_lines.length }}</td>
					</tr>
					<tr v-if="paymentRequired">
						<td class="pr-4 font-bold">
							{{ t('app.total_price_without_fees') }}
						</td>
						<td>&euro; {{ registration.total_price }}</td>
					</tr>
				</table>

				<button-base v-if="registration.registration_lines.length > 0 && registration.event && can(registration.event, 'register')" :href="route('registrations.finish', { registration: registration.uuid })" class="w-[165px]">
					<template v-if="paymentRequired">
						{{ t('app.proceed_to_payment') }}
					</template>
					<template v-else>
						{{ t('app.finish_registration') }}
					</template>
				</button-base>
			</div>
		</div>

		<div v-if="! isEmpty(errors)" class="max-h-[200px] overflow-y-scroll border border-red-600 bg-red-100 p-2 text-sm text-red-600">
			<ul class="list-inside list-disc">
				<li v-for="error in errors" :key="error">
					{{ error }}
				</li>
			</ul>
		</div>

		<message-warning v-if="registration.late_entry">
			{{ t('app.late_entry_description') }}
		</message-warning>

		<message-warning v-if="registration.extra_late_entry">
			{{ t('app.extra_late_entry_description') }}
		</message-warning>

		<message-error v-if="!registration.event || (registration.event && !can(registration.event, 'register'))">
			{{ t('app.registration_for_this_event_is_closed') }}
		</message-error>

		<div>
			<div v-if="registration.registration_lines.length" class="relative ml-auto sm:max-w-[250px]">
				<form-input-text v-model="searchQuery" :placeholder="t('app.search_for_a_rider')" class="pr-8" />

				<i-gg-search class="absolute right-2 top-1/2 -translate-y-1/2 select-none text-gray-400" />
			</div>

			<template v-if="registration.registration_lines.length">
				<table-base>
					<template #head>
						<table-row>
							<table-column class="pl-0">
								{{ t('app.name') }}
							</table-column>
							<table-column>{{ registration.event.rider_organisation ? t(`app.organisation_id.${registration.event.rider_organisation}`) : t('app.organisation_id.fallback') }}</table-column>
							<table-column>{{ t('app.category') }}</table-column>
							<table-column v-if="paymentRequired">
								{{ t('app.price') }}
							</table-column>
							<table-column />
						</table-row>
					</template>
					<table-row v-if="isEmpty(filteredRegistrationLines)">
						<table-column class="pl-0" colspan="5">
							{{ t('app.no_results_found') }}
						</table-column>
					</table-row>
					<table-row v-for="registrationLine in filteredRegistrationLines" :key="registrationLine.uuid">
						<table-column class="pl-0">
							{{ registrationLine.full_name }}
						</table-column>
						<table-column>{{ registrationLine.organisation_id }}</table-column>
						<table-column>{{ registrationLine.event_category }}</table-column>
						<table-column v-if="paymentRequired">
							<template v-if="registrationLine.price">
								&euro; {{ registrationLine.price }}
							</template>
							<span v-else class="flex items-center space-x-2 text-red-600">
								<i-gg-close-o class="mr-2 h-4 w-4" />
								{{ t('app.unavailable') }}
							</span>
						</table-column>
						<table-column class="text-right">
							<template v-if="registration.event && can(registration.event, 'register')">
								<button-base variant="link" :href="route('registrationLines.edit', { registrationLine: registrationLine.uuid })">
									{{ t('app.edit') }}
								</button-base>
								<span class="px-2 text-gray-400">|</span>
								<button-base variant="link" @click="detachRegistrationLine = registrationLine">
									{{ t('app.detach') }}
								</button-base>
							</template>
						</table-column>
					</table-row>
				</table-base>

				<table class="ml-auto text-sm text-gray-500">
					<tr>
						<td class="pr-4 font-bold">
							{{ t('app.total_entries') }}
						</td>
						<td>{{ registration.registration_lines.length }}</td>
					</tr>
					<tr v-if="paymentRequired">
						<td class="pr-4 font-bold">
							{{ t('app.total_price_without_fees') }}
						</td>
						<td>&euro; {{ registration.total_price }}</td>
					</tr>
				</table>
			</template>
			<p v-else>
				{{ t('app.no_riders_added_yet') }}
			</p>
		</div>

		<div class="flex justify-between">
			<button-base v-if="registration.event" variant="secondary" :href="route('events.registrations.index', { event: registration.event?.uuid })">
				{{ t('app.back_to_registrations') }}
			</button-base>

			<button-base v-if="registration.registration_lines.length > 0 && registration.event && can(registration.event, 'register')" :href="route('registrations.finish', { registration: registration.uuid })">
				<template v-if="paymentRequired">
					{{ t('app.proceed_to_payment') }}
				</template>
				<template v-else>
					{{ t('app.finish_registration') }}
				</template>
			</button-base>
		</div>
	</page-container>
</template>
