<script setup lang="ts">
import isEmpty from 'lodash/isEmpty'
import { c2c } from 'vue-c2c'
import PreparedEventCategoryData = App.Data.Event.PreparedEventCategoryData
import RegistrationTotalData = App.Data.Registration.RegistrationTotalData
import InfoModal from '@/components/info-modal.vue'

const $props = defineProps<{
	registration: App.Data.Registration.RegistrationData & {
		event: App.Data.EventData
	}
	registrationLineCount: number
	totals: {
		[key: string]: RegistrationTotalData
	}
	checkboxOptions: App.Data.Registration.RegistrationCheckboxOptionData[]
	currentSearchQuery: string | null
	additionalCheckboxOptions: PreparedEventCategoryData[]
	notAllowedByFederation: {
		name: string
		organisation_id: string
	}[]
	categoryPopups: {
		[key: string]:
		{
			title: string
			text: string
		}
	}
	registrationErrorPopupTitle: string | null
	registrationErrorPopupText: string | null
}>()

defineLayoutProperties({
	pageTitle: t('app.registration_title', { number: $props.registration.id, event: $props.registration.event?.name }),
})

const { user } = useUser()
const paymentRequired = computed(() => $props.registration.event?.payment_required)
const errors = useProperty<{ [key: string | number]: string }>('errors')
const isLoading = ref<boolean>(false)
const useInfoModal = c2c(InfoModal)

const additionalCheckboxOptionsHeaders = computed(() => {
	return $props.additionalCheckboxOptions.map((additionalCheckboxOption) => {
		return additionalCheckboxOption.name
	})
})

const searchQuery = ref<string>($props.currentSearchQuery ?? '')
watchDebounced(
	searchQuery,
	(value) => {
		isLoading.value = true

		router.reload({
			data: {
				searchQuery: searchQuery.value,
			},
		}).finally(() => {
			isLoading.value = false
		})
	},
	{ debounce: 200, maxWait: 5000 },
)

const registrationErrorTitle = ref<string | null>('')
const registrationErrorContent = ref<string | null>('')
const { hide: hideRegistrationErrorInfoModal, toggle: toggleRegistrationErrorInfoModal, visible: visibleRegistrationErrorInfoModal, exposed: exposedRegistrationErrorInfoModal } = useInfoModal({
	title: registrationErrorTitle,
	content: registrationErrorContent,
	closeText: t('app.close'),
}, {
	emits: {
		// @ts-ignore-next-line
		onClose() {
			hideRegistrationErrorInfoModal()
		},
	},
})

const selectionTitle = ref<string>('')
const selectionContent = ref<string>('')
const { hide: hideSelectionInfoModal, toggle: toggleSelectionInfoModal, visible: visibleSelectionInfoModal, exposed: exposedSelectionInfoModal } = useInfoModal({
	title: selectionTitle,
	content: selectionContent,
	closeText: t('app.close'),
}, {
	emits: {
		// @ts-ignore-next-line
		onClose() {
			hideSelectionInfoModal()
		},
	},
})

const handleCheckboxOptionClick = (checkboxOption: App.Data.Registration.RegistrationCheckboxOptionData) => {
	isLoading.value = true
	if (checkboxOption.registration_line) {
		router.delete(route('registrationLines.destroy', { registrationLine: checkboxOption.registration_line }), {
			preserveState: true,
			preserveScroll: true,
		}).finally(() => {
			isLoading.value = false
		})
	} else {
		if ($props.categoryPopups[checkboxOption.event_category]) {
			selectionTitle.value = $props.categoryPopups[checkboxOption.event_category].title
			selectionContent.value = $props.categoryPopups[checkboxOption.event_category].text

			toggleSelectionInfoModal()
		}

		router.post(route('registrations.registrationLines.store', { registration: $props.registration.uuid }), {
			preserveState: true,
			preserveScroll: true,
			data: {
				organisation_id: checkboxOption.organisation_id,
				birthdate: checkboxOption.birthdate,
				event_category: checkboxOption.event_category,
			},
		})
			.finally(() => {
				if (
					$props.registrationErrorPopupTitle !== null
					|| $props.registrationErrorPopupText !== null
				) {
					if (visibleSelectionInfoModal.value) {
						hideSelectionInfoModal()
					}

					registrationErrorTitle.value = $props.registrationErrorPopupTitle
					registrationErrorContent.value = $props.registrationErrorPopupText
					toggleRegistrationErrorInfoModal()
				}

				isLoading.value = false
			})
	}
}

const handleCheckboxOptionAdditionalClick = (
	checkboxOption: App.Data.Registration.RegistrationCheckboxOptionData,
	additionalCheckboxOption: {
		name: string
		registration_line: number | null
	},
) => {
	if (!checkboxOption.registration_line) {
		return
	}

	isLoading.value = true
	if (additionalCheckboxOption.registration_line) {
		router.delete(route('registrationLines.destroy', { registrationLine: additionalCheckboxOption.registration_line }), {
			preserveState: true,
			preserveScroll: true,
		}).finally(() => {
			isLoading.value = false
		})
	} else {
		router.post(route('registrationLines.storeAddition', { registrationLine: checkboxOption.registration_line }), {
			preserveState: true,
			preserveScroll: true,
			data: {
				addition: additionalCheckboxOption.name,
			},
		}).finally(() => {
			isLoading.value = false
		})
	}
}

const printPage = () => {
	window.print()
}

const { hide, toggle, visible, exposed } = useInfoModal({
	title: t('app.transponder_required_modal_title'),
	content: t('app.transponder_required_modal_content'),
	closeText: t('app.close'),
}, {
	emits: {
		// @ts-ignore-next-line
		onClose() {
			hide()
		},
	},
})
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<page-container class="space-y-8">
		<div class="flex flex-col justify-between space-y-4 md:flex-row md:space-x-4 md:space-y-0">
			<div v-if="registration.event && can(registration.event, 'register')" class="flex space-x-4">
				<!--				<button-base :href="route('riders.create', { organisation: registration.event.rider_organisation })">-->
				<!--					{{ t('app.add_new_rider') }}-->
				<!--				</button-base>-->

				<button-base @click="printPage">
					<i-gg-printer class="h-4 w-4" aria-hidden="true" />
					{{ t('app.print_page') }}
				</button-base>
			</div>

			<div v-if="checkboxOptions.length > 10" class="mb-4 flex flex-col justify-end gap-4 md:flex-row">
				<div class="flex flex-wrap items-center gap-2 text-sm">
					<div v-for="(total, key) in totals" :key="'top-total' + key">
						<template v-if="key !== 'subtotal'">
							{{ t('app.total') }} {{ total.description }} <template v-if="total.count > 0">
								{{ total.count }}
							</template>
							<template v-if="paymentRequired">
								[&euro; {{ total.price }}]
							</template>
						</template>
					</div>
					<div v-if="paymentRequired">
						{{ t('app.subtotal') }} &euro; {{ totals['subtotal']?.price }}
					</div>
				</div>

				<button-base v-if="registrationLineCount > 0 && registration.event && can(registration.event, 'register')" :href="route('registrations.finish', { registration: registration.uuid })" class="w-[165px]">
					<template v-if="paymentRequired">
						{{ t('app.proceed_to_payment') }}
					</template>
					<template v-else>
						{{ t('app.finish_registration') }}
					</template>
				</button-base>
			</div>
		</div>

		<div v-if="! isEmpty(errors)" class="max-h-[200px] overflow-y-scroll border border-red-600 bg-red-100 p-2 text-sm text-red-600">
			<ul class="list-inside list-disc">
				<li v-for="error in errors" :key="error">
					{{ error }}
				</li>
			</ul>
		</div>

		<message-warning v-if="registration.late_entry">
			{{ t('app.late_entry_description') }}
		</message-warning>

		<message-warning v-if="registration.extra_late_entry">
			{{ t('app.extra_late_entry_description') }}
		</message-warning>

		<message-error v-if="!registration.event || (registration.event && !can(registration.event, 'register'))">
			{{ t('app.registration_for_this_event_is_closed') }}
		</message-error>

		<message-warning v-if="!isEmpty(notAllowedByFederation)">
			<div>
				<p>{{ t('app.no_permission_to_register_by_federation') }}</p>
				<ul class="list-inside list-disc">
					<li v-for="notAllowedByFederationItem in notAllowedByFederation" :key="notAllowedByFederationItem.organisation_id">
						{{ notAllowedByFederationItem.name }} ({{ notAllowedByFederationItem.organisation_id }})
					</li>
				</ul>
			</div>
		</message-warning>

		<div>
			<div class="relative ml-auto sm:max-w-[250px]">
				<form-input-text v-model="searchQuery" :placeholder="t('app.search_for_a_rider')" class="pr-8" />

				<i-gg-search class="absolute right-2 top-1/2 -translate-y-1/2 select-none text-gray-400" />
			</div>

			<template v-if="checkboxOptions">
				<table-base :class="{ 'pointer-events-none opacity-50': isLoading }">
					<template #head>
						<table-row>
							<table-column class="whitespace-break-spaces pl-0 text-center text-xs md:w-1 md:whitespace-nowrap md:text-left md:text-sm">
								{{ t('app.sign_up') }}
							</table-column>
							<table-column v-for="additionalCheckboxOptionsHeader in additionalCheckboxOptionsHeaders" :key="additionalCheckboxOptionsHeader" class="whitespace-break-spaces pl-0 text-center text-xs md:w-1 md:whitespace-nowrap md:text-left md:text-sm">
								{{ additionalCheckboxOptionsHeader }}
							</table-column>
							<table-column>
								{{ t('app.name') }}
							</table-column>
							<table-column>{{ t('app.category') }}</table-column>
							<table-column v-if="paymentRequired">
								{{ t('app.price') }}
							</table-column>
							<table-column>{{ registration.event.rider_organisation ? t(`app.organisation_id.${registration.event.rider_organisation}`) : t('app.organisation_id.fallback') }}</table-column>
						</table-row>
					</template>
					<table-row v-if="isEmpty(checkboxOptions) && searchQuery">
						<table-column class="pl-0" colspan="5">
							{{ t('app.no_results_found') }}
						</table-column>
					</table-row>
					<table-row v-for="checkboxOption in checkboxOptions" :key="checkboxOption.organisation_id + '-' + checkboxOption.event_category">
						<table-column class="pl-0">
							<div class="flex justify-center pl-0">
								<template v-if="checkboxOption.registration_line !== null">
									<div class="flex h-4 w-4 cursor-pointer items-center justify-center rounded border-2 border-primary bg-primary text-white focus:ring-primary print:hidden" @click="handleCheckboxOptionClick(checkboxOption)">
										<i-gg-check class="block min-h-5 min-w-5" />
									</div>

									<i-gg-check class="hidden h-8 w-8 text-primary print:block" />
								</template>
								<div v-else class="h-4 w-4 cursor-pointer rounded border-2 border-gray-900 text-primary focus:ring-primary" @click="handleCheckboxOptionClick(checkboxOption)" />
							</div>
						</table-column>
						<table-column v-for="additionalCheckboxOption in additionalCheckboxOptions" :key="checkboxOption.organisation_id + '-' + checkboxOption.event_category + '-' + additionalCheckboxOption.name" class="text-center">
							<div class="flex justify-center">
								<template v-if="checkboxOption.registration_line !== null && additionalCheckboxOption.combinable_category_names?.includes(checkboxOption.event_category)">
									<template v-if="checkboxOption.required_additional_options?.includes(additionalCheckboxOption.name)">
										<div class="flex h-4 w-4 cursor-not-allowed items-center justify-center rounded border-2 border-primary bg-primary text-white focus:ring-primary print:hidden" @click="toggle">
											<i-gg-check class="block min-h-5 min-w-5" />
										</div>
									</template>
									<template v-else>
										<template v-if="checkboxOption.checked_additional_options[additionalCheckboxOption.name]">
											<div class="flex h-4 w-4 cursor-pointer items-center justify-center rounded border-2 border-primary bg-primary text-white focus:ring-primary print:hidden" @click="handleCheckboxOptionAdditionalClick(checkboxOption, { name: additionalCheckboxOption.name, registration_line: checkboxOption.checked_additional_options[additionalCheckboxOption.name] })">
												<i-gg-check class="block min-h-5 min-w-5" />
											</div>

											<i-gg-check class="hidden h-8 w-8 text-primary print:block" />
										</template>
										<div v-else class="h-4 w-4 cursor-pointer rounded border-2 border-gray-900 text-primary focus:ring-primary" @click="handleCheckboxOptionAdditionalClick(checkboxOption, { name: additionalCheckboxOption.name, registration_line: null })" />
									</template>
								</template>
							</div>
						</table-column>
						<table-column>
							{{ checkboxOption.full_name }}
						</table-column>
						<table-column>{{ checkboxOption.event_category }}</table-column>
						<table-column v-if="paymentRequired">
							<price v-if="checkboxOption.price">
								{{ checkboxOption.price }}
							</price>
							<span v-else class="flex items-center space-x-2 text-red-600">
								<i-gg-close-o class="mr-2 h-4 w-4" />
								{{ t('app.unavailable') }}
							</span>
						</table-column>
						<table-column>{{ checkboxOption.organisation_id }}</table-column>
					</table-row>
				</table-base>
			</template>
			<p v-else>
				{{ t('app.no_rider_options_found') }}
			</p>
		</div>

		<div class="justify-between md:flex">
			<button-base v-if="registration.event" variant="secondary" :href="route('events.registrations.index', { event: registration.event?.uuid })">
				{{ t('app.back_to_registrations') }}
			</button-base>

			<div class="mt-4 flex flex-col justify-end gap-4 md:ml-4 md:mt-0 md:flex-row">
				<div class="flex flex-wrap items-center gap-2 text-sm">
					<div v-for="(total, key) in totals" :key="'bottom-total' + key">
						<template v-if="key !== 'subtotal'">
							{{ t('app.total') }} {{ total.description }} <template v-if="total.count > 0">
								{{ total.count }}
							</template>
							<template v-if="paymentRequired">
								[&euro; {{ total.price }}]
							</template>
						</template>
					</div>
					<div v-if="paymentRequired">
						{{ t('app.subtotal') }} &euro; {{ totals['subtotal']?.price }}
					</div>
				</div>
				<button-base v-if="registrationLineCount > 0 && registration.event && can(registration.event, 'register')" :href="route('registrations.finish', { registration: registration.uuid })" class="w-[165px]">
					<template v-if="paymentRequired">
						{{ t('app.proceed_to_payment') }}
					</template>
					<template v-else>
						{{ t('app.finish_registration') }}
					</template>
				</button-base>
			</div>
		</div>
	</page-container>
</template>
