export function useRegistrationLineSearch(registrationLines: Ref<App.Data.Registration.RegistrationLineData[]>) {
	const searchQuery = ref<string>('')
	const filteredRegistrationLines = ref<App.Data.Registration.RegistrationLineData[]>([])

	registerHook('navigated', () => {
		searchQuery.value = ''
	})

	watchDebounced(
		searchQuery,
		(value) => {
			filteredRegistrationLines.value = registrationLines.value.filter((registrationLine) => {
				return (
					registrationLine.full_name.toLowerCase().includes(value.toLowerCase())
					|| registrationLine.organisation_id.toLowerCase().includes(value.toLowerCase())
				)
			})
		},
		{ debounce: 200, maxWait: 5000 },
	)

	return {
		searchQuery,
		filteredRegistrationLines: computed(() => {
			if (searchQuery.value === '') {
				return registrationLines.value
			}

			return filteredRegistrationLines.value
		}),
	}
}
