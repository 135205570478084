<script setup lang="ts">
const form = useForm({
	method: 'POST',
	url: route('verification.send'),
	fields: {},
})

const status = useProperty('status')
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/auth.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<form class="space-y-5" @submit.prevent="form.submit">
		<message-wrapper>
			<message-success v-if="status === 'verification-link-sent'">
				{{ t('app.verify_email_page_link_sent') }}
			</message-success>
		</message-wrapper>

		<p>{{ t('app.verify_email_page_content') }}</p>

		<div class="flex flex-wrap items-center space-x-2">
			<button-base type="submit" :loading="form.processing">
				{{ t('app.resend_verification_email') }}
			</button-base>

			<p class="text-gray-400">
				{{ t('app.or') }}
			</p>

			<button-base :href="route('logout')" variant="link" method="POST">
				{{ t('app.logout') }}
			</button-base>
		</div>
	</form>
</template>
