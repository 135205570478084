<script setup lang="ts">
const $props = defineProps<{
	filters: App.Data.Registration.SearchRegistrationLineData
	event: App.Data.EventData
	registrationLines: App.Data.Registration.RegistrationLineData[]
	totalRegistrationLines: number
}>()

const searchForm = ref($props.filters)
watchDebounced(
	searchForm.value,
	() => {
		router.get(route('events.entries', { event: $props.event.uuid }), {
			data: searchForm.value,
			preserveState: true,
		})
	},
	{ debounce: 200, maxWait: 5000 },
)

defineLayoutProperties({
	pageTitle: $props.event.name,
	pageTitleLink: route('events.show', { event: $props.event.uuid }),
})
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<page-container>
		<template v-if="$props.registrationLines">
			<div class="flex flex-wrap gap-2">
				<div class="flex flex-wrap items-center gap-2 text-sm text-gray-500">
					{{ t('app.total_entries') }} {{ totalRegistrationLines }}
				</div>
				<div class="relative w-full sm:ml-auto sm:max-w-[250px]">
					<form-input-text v-model="searchForm.keyword" :placeholder="t('app.search_for_a_rider')" class="pr-8" />

					<i-gg-search class="absolute right-2 top-1/2 -translate-y-1/2 select-none text-gray-400" />
				</div>
			</div>

			<table-base>
				<template #head>
					<table-row>
						<table-head class="pl-0">
							{{ t('app.name') }}
						</table-head>
						<table-head>{{ t('app.category') }}</table-head>
					</table-row>
				</template>
				<table-row v-for="registrationLine in $props.registrationLines" :key="registrationLine.uuid">
					<table-column class="pl-0">
						<div class="flex items-center gap-2">
							<img
								v-if="registrationLine.ioc_code"
								:src="`/images/flags/` + registrationLine.ioc_code.toUpperCase() + `.PNG`"
								class="h-4 w-6"
							/>
							{{ registrationLine.full_name }}
						</div>
					</table-column>
					<table-column>
						{{ registrationLine.event_category }}
					</table-column>
				</table-row>
			</table-base>
		</template>
	</page-container>
</template>
