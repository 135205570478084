export function usePageTitle() {
	const context = useContext()
	const pageTitle = ref<string>('')

	function updatePageTitle() {
		const component = context.value?.view.component || ''
		pageTitle.value = t(`app.${component.replace('/', '.')}.title`)
	}

	updatePageTitle()
	registerHook('navigated', updatePageTitle)

	return {
		pageTitle: readonly(pageTitle),
	}
}
