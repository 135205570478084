<script setup lang="ts">
const $props = defineProps<{
	registration: App.Data.Registration.RegistrationData
	categories: App.Data.EventCategoryData[]
}>()

const { organisationIdMaskOptions, birthdateMaskOptions, transponderMaskOptions } = useInputMasks()
const { closeLocally } = useDialog()

const form = useForm<App.Data.Registration.CreateRegistrationLineData>({
	method: 'POST',
	url: route('registrations.registrationLines.store', { registration: $props.registration.uuid }),
	fields: {
		organisation_id: '',
		birthdate: '',
		event_category: '',
		mylaps_transponder_20inch: '',
		mylaps_transponder_cruiser: '',
	},
	hooks: {
		success: () => {
			closeLocally()
		},
	},
})

const selectedRider = ref<App.Data.Rider.RiderData | null>()
const query = ref('')
const riderOptions = ref<App.Data.Rider.RiderData[]>([])
watchDebounced(
	query,
	(value) => {
		fetch(route('riders.search', { keyword: value, organisation: $props.registration.event?.rider_organisation ?? 'uci' }))
			.then((response) => response.json())
			.then((data) => {
				riderOptions.value = data
			})
	},
	{ debounce: 200, maxWait: 5000 },
)
watch(selectedRider, (value) => {
	if (value) {
		form.fields.organisation_id = value.organisation_id
		form.fields.birthdate = value.birthdate
		form.fields.mylaps_transponder_20inch = value.mylaps_transponder_20inch
		form.fields.mylaps_transponder_cruiser = value.mylaps_transponder_cruiser
	}
})
function displayValue(item: unknown) {
	return (item as App.Data.Rider.RiderData).full_name
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<modal-base :title="t('app.add_rider')">
		<div class="mb-4 border-b border-gray-300 pb-4">
			<form-input-wrapper :label="t('app.search_for_a_rider_in_own_database')" for="search_rider">
				<headless-combobox v-model="selectedRider">
					<div class="relative mt-1">
						<headless-combobox-input id="search_rider" :display-value="displayValue" :placeholder="t('app.search_for_a_rider_placeholder', { organisation_id: t(`app.organisation_id.${registration.event?.rider_organisation ?? 'fallback'}`) })" class="w-full border-gray-300" @change="query = $event.target.value" />
						<headless-transition-root
							leave="transition ease-in duration-100"
							leave-from="opacity-100"
							leave-to="opacity-0"
							@after-leave="query = ''"
						>
							<headless-combobox-options :class="{ 'hidden': query === '' }" class="absolute mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-sm shadow-lg">
								<div
									v-if="riderOptions.length === 0 && query !== ''"
									class="relative cursor-default select-none p-2 text-gray-500"
								>
									{{ t('app.no_results_found') }}
								</div>

								<headless-combobox-option v-for="riderOption in riderOptions" v-slot="{ active }" :key="riderOption.uuid" as="template" :value="riderOption">
									<li class="cursor-pointer p-2 hover:bg-primary-100" :class="{ 'bg-primary-100': active }">
										{{ riderOption.full_name }}
									</li>
								</headless-combobox-option>
							</headless-combobox-options>
						</headless-transition-root>
					</div>
				</headless-combobox>
			</form-input-wrapper>
		</div>

		<form class="space-y-5" @submit.prevent="form.submit">
			<form-input-wrapper :label="t(`app.organisation_id.${registration.event?.rider_organisation ?? 'fallback'}`)" for="organisation_id" :errors="form.errors.organisation_id">
				<form-input-text
					id="organisation_id"
					v-model="form.fields.organisation_id"
					v-maska:[organisationIdMaskOptions]
					required
					autofocus
				/>
			</form-input-wrapper>

			<form-input-wrapper :label="t('app.birthdate')" for="birthdate" :errors="form.errors.birthdate">
				<form-input-text
					id="birthdate"
					v-model="form.fields.birthdate"
					v-maska:[birthdateMaskOptions]
					placeholder="dd/mm/yyyy"
					required
				/>
			</form-input-wrapper>

			<form-input-wrapper :label="t('app.category')" for="category" :errors="form.errors.event_category">
				<form-select id="category" v-model="form.fields.event_category">
					<option value="">
						{{ t('app.select_category') }}
					</option>
					<option v-for="category in categories" :key="category.name" :value="category.name">
						{{ category.name }}
					</option>
				</form-select>
			</form-input-wrapper>

			<form-input-wrapper :label="t('app.mylaps_transponder_20inch')" for="mylaps_transponder_20inch" :errors="form.errors.mylaps_transponder_20inch" optional>
				<form-input-text
					id="mylaps_transponder_20inch"
					v-model="form.fields.mylaps_transponder_20inch"
					v-maska:[transponderMaskOptions]
				/>
			</form-input-wrapper>

			<form-input-wrapper :label="t('app.mylaps_transponder_cruiser')" for="mylaps_transponder_cruiser" :errors="form.errors.mylaps_transponder_cruiser" optional>
				<form-input-text
					id="mylaps_transponder_cruiser"
					v-model="form.fields.mylaps_transponder_cruiser"
					v-maska:[transponderMaskOptions]
				/>
			</form-input-wrapper>

			<div class="flex justify-end space-x-4">
				<button-base variant="secondary" type="button" @click="closeLocally">
					{{ t('app.cancel') }}
				</button-base>

				<button-base type="submit" :loading="form.processing">
					{{ t('app.save') }}
				</button-base>
			</div>
		</form>
	</modal-base>
</template>
