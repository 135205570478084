<script setup lang="ts">
import isEmpty from 'lodash/isEmpty'

const $props = defineProps<{
	event: App.Data.EventData
	exampleFileUrl: string | null
}>()

defineLayoutProperties({
	pageTitle: t('app.registrations.bulk-create.title', { event: $props.event.name }),
})

const errors = useProperty<{ [key: string | number]: string }>('errors')
const form = useForm<App.Data.Registration.BulkCreateRegistrationData>({
	method: 'POST',
	url: route('events.registrations.bulk-store', { event: $props.event.uuid }),
	fields: {
		import_file: undefined as File | undefined,
	},
})

function onFileChange(event: Event) {
	const target = event.target as HTMLInputElement
	form.fields.import_file = target.files?.[0]
}
</script>

					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<page-container>
		<form class="space-y-5" @submit.prevent="form.submit">
			<div v-if="form.processing" class="flex items-center gap-2">
				<i-gg-alarm class="h-4 w-4 animate-bounce text-primary" />
				{{ t('app.importing') }}
			</div>
			<template v-else>
				<div class="space-y-2">
					<p>{{ t('app.import_registration_description') }}</p>

					<a v-if="exampleFileUrl" :href="exampleFileUrl + '?v=' + Date.now()" target="_blank" class="flex items-center gap-2 text-primary hover:text-primary-700">
						<i-gg-import class="h-4 w-4" aria-hidden="true" />
						{{ t('app.download_example_file') }}
					</a>
				</div>

				<input type="file" accept=".xlsx" @change="onFileChange" />

				<div v-if="! isEmpty(errors)" class="max-h-[200px] overflow-y-scroll border border-red-600 bg-red-100 p-2 text-sm text-red-600">
					<ul class="list-inside list-disc">
						<li v-for="error in errors" :key="error">
							{{ error }}
						</li>
					</ul>
				</div>
			</template>

			<div class="flex justify-end space-x-4">
				<button-base variant="secondary" :href="route('events.registrations.index', { event: event.uuid })">
					{{ t('app.cancel') }}
				</button-base>

				<button-base type="submit" :loading="form.processing">
					{{ t('app.import') }}
				</button-base>
			</div>
		</form>
	</page-container>
</template>
