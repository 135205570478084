<script setup lang="ts">
const props = defineProps<{
	modelValue: string | number | null
}>()

const emit = defineEmits<{
	(e: 'update:modelValue', value: string | number): void
}>()

const value = useVModel(props, 'modelValue', emit)

const input = ref<HTMLInputElement | null>(null)
onMounted(() => {
	if (input.value && input.value.hasAttribute('autofocus')) {
		input.value.focus()
	}
})
</script>

<template>
	<input
		ref="input"
		v-model="value"
		type="text"
		class="w-full border-gray-300 px-3 py-2.5 text-sm transition focus:border-gray-700 focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-100"
	/>
</template>
