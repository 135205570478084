<script setup lang="ts">
const $props = defineProps<{
	filters: App.Data.Registration.SearchRegistrationLineData
	event: App.Data.EventData
	registrationLines: App.Data.Registration.RegistrationLineData[]
	totalRegistrationLines: number
	totalRegistrationLinesWithoutCancelled: number
	totalRegistrationLinesCancelled: number
}>()

const searchForm = ref($props.filters)
watchDebounced(
	searchForm.value,
	() => {
		router.get(route('member-history.show', { event: $props.event.uuid }), {
			data: searchForm.value,
			preserveState: true,
		})
	},
	{ debounce: 200, maxWait: 5000 },
)

defineLayoutProperties({
	pageTitle: $props.event.name,
})

const printPage = () => {
	window.print()
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<page-container class="space-y-2">
		<template v-if="totalRegistrationLines > 0">
			<div class="flex flex-wrap justify-between gap-4">
				<div class="flex flex-wrap items-center gap-4">
					<button-base @click="printPage">
						<i-gg-printer class="h-4 w-4" aria-hidden="true" />
						{{ t('app.print_page') }}
					</button-base>

					<p class="flex flex-wrap gap-2 text-sm text-gray-500">
						<span>{{ t('app.registrations_count', { count: totalRegistrationLines }) }}</span>
						<span>{{ t('app.cancellations_count', { count: totalRegistrationLinesCancelled }) }}</span>
						<span>{{ t('app.entries_count', { count: totalRegistrationLinesWithoutCancelled }) }}</span>
					</p>
				</div>

				<div class="relative w-full sm:ml-auto sm:max-w-[250px]">
					<form-input-text v-model="searchForm.keyword" :placeholder="t('app.search_for_a_rider')" class="pr-8" />

					<i-gg-search class="absolute right-2 top-1/2 -translate-y-1/2 select-none text-gray-400" />
				</div>
			</div>

			<table-base>
				<template #head>
					<table-row>
						<table-column class="pl-0">
							{{ t('app.name') }}
						</table-column>
						<table-column>{{ event.rider_organisation ? t(`app.organisation_id.${event.rider_organisation}`) : t('app.organisation_id.fallback') }}</table-column>
						<table-column>{{ t('app.category') }}</table-column>
						<table-column>{{ t('app.cancelled') }}</table-column>
					</table-row>
				</template>
				<table-row v-for="registrationLine in registrationLines" :key="registrationLine.uuid">
					<table-column class="pl-0">
						{{ registrationLine.full_name }}
					</table-column>
					<table-column>{{ registrationLine.organisation_id }}</table-column>
					<table-column>{{ registrationLine.event_category }}</table-column>
					<table-column :class="{ 'text-red-500': registrationLine.cancelled_at }">
						{{ registrationLine.cancelled_at ? t('app.yes') : t('app.no') }}
					</table-column>
				</table-row>
			</table-base>
		</template>
		<p v-else class="text-md">
			{{ t('app.no_results_found') }}
		</p>
	</page-container>
</template>
