const notifications = ref<App.Support.Notifications.Data.NotificationData[]>([])

export function setupNotifications() {
	const notifications = useProperty('notifications')

	watch(
		notifications,
		() => {
			const { push } = useNotifications()
			// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
			for (const [key, notification] of Object.entries(notifications.value)) {
				push(notification as App.Support.Notifications.Data.NotificationData)
			}
		},
		{ immediate: true },
	)
}

export function pushNotification(notification: App.Support.Notifications.Data.NotificationData) {
	notifications.value.push(notification)

	if (notification.options.decay !== false) {
		setTimeout(
			() => dismissNotification(notification),
			typeof notification.options.decay === 'number' ? notification.options.decay : 5000,
		)
	}

	return {
		dismiss: () => dismissNotification(notification),
	}
}

export function dismissNotification(notification: App.Support.Notifications.Data.NotificationData) {
	const index = notifications.value.findIndex(({ state }) => state.id === notification.state.id)

	if (index === -1) {
		return
	}

	notifications.value.splice(index, 1)
}

export function useNotifications() {
	return {
		notifications: readonly(notifications),
		push: pushNotification,
		dismiss: dismissNotification,
	}
}

export { default as NotificationRegion } from '@/components/notification/region.vue'
