<script setup lang="ts">
defineProps<{
	events: Paginator<App.Data.EventData>
}>()
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<page-container class="space-y-2">
		<template v-if="events.data.length">
			<table-base>
				<template #head>
					<table-row>
						<table-column class="pl-0">
							{{ t('app.event') }}
						</table-column>
						<table-column>{{ t('app.event_date') }}</table-column>
						<table-column />
					</table-row>
				</template>
				<table-row v-for="event in events.data" :key="event.uuid">
					<table-column class="pl-0">
						{{ event.name }}
					</table-column>
					<table-column>
						{{ event.event_date }}
					</table-column>
					<table-column class="text-right">
						<button-base variant="link" :href="route('member-history.show', { event: event.uuid })">
							{{ t('app.view') }}
						</button-base>
					</table-column>
				</table-row>
			</table-base>

			<pagination :links="events.links" />
		</template>
		<p v-else class="text-md">
			{{ t('app.no_results_found') }}
		</p>
	</page-container>
</template>
