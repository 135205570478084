<script lang="ts" setup>
const $props = defineProps<{
	organisations: App.Enums.OrganisationEnum[]
}>()

const { user } = useUser()
const status = useProperty('status')

const accountForm = useForm({
	method: 'PUT',
	url: route('user-profile-information.update'),
	reset: false,
	fields: {
		email: user.value.email,
		invoice_info_line1: user.value.invoice_info_line1,
		invoice_info_line2: user.value.invoice_info_line2,
		invoice_info_line3: user.value.invoice_info_line3,
		invoice_info_line4: user.value.invoice_info_line4,
		invoice_info_line5: user.value.invoice_info_line5,
	},
	errorBag: 'updateProfileInformation',
})

const updatePasswordForm = useForm({
	method: 'PUT',
	url: route('user-password.update'),
	fields: {
		current_password: '',
		password: '',
		password_confirmation: '',
	},
	errorBag: 'updatePassword',
})

function getRiderInfo(organisation: App.Enums.OrganisationEnum): string | null {
	const rider = user.value.bound_riders?.find((rider) => rider.organisation === organisation)

	if (!rider) {
		return null
	}

	return `${rider.full_name} - ${rider.organisation_id}`
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<page-container class="space-y-4">
		<heading3>{{ t('app.account_information') }}</heading3>

		<message-wrapper>
			<message-success v-if="status === 'profile-information-updated'">
				{{ t('app.update_success') }}
			</message-success>
		</message-wrapper>

		<form class="flex flex-col space-y-5" @submit.prevent="accountForm.submit">
			<form-input-wrapper :label="t('app.name')">
				<form-input-readonly>{{ user?.name }}</form-input-readonly>
			</form-input-wrapper>

			<template v-if="user?.type === 'entity' && user.entity">
				<form-input-wrapper :label="t(`app.entity_${user.entity.type}_name`)">
					<form-input-readonly>{{ user?.entity?.name }}</form-input-readonly>
				</form-input-wrapper>
			</template>

			<form-input-wrapper :label="t('app.billing_information')">
				<div class="space-y-2">
					<form-input-text
						id="invoice_info_line1"
						v-model="accountForm.fields.invoice_info_line1"
						type="text"
						:placeholder="$t('app.invoice_line1_placeholder')"
						maxlength="60"
					/>
					<form-input-text
						id="invoice_info_line2"
						v-model="accountForm.fields.invoice_info_line2"
						type="text"
						:placeholder="$t('app.invoice_line2_placeholder')"
						maxlength="60"
					/>
					<form-input-text
						id="invoice_info_line3"
						v-model="accountForm.fields.invoice_info_line3"
						type="text"
						:placeholder="$t('app.invoice_line3_placeholder')"
						maxlength="60"
					/>
					<form-input-text
						id="invoice_info_line4"
						v-model="accountForm.fields.invoice_info_line4"
						type="text"
						:placeholder="$t('app.invoice_line4_placeholder')"
						maxlength="60"
					/>
					<form-input-text
						id="invoice_info_line5"
						v-model="accountForm.fields.invoice_info_line5"
						type="text"
						:placeholder="$t('app.invoice_line5_placeholder')"
						maxlength="60"
					/>
				</div>
			</form-input-wrapper>

			<form-input-wrapper :label="t('app.email')" for="email" :errors="accountForm.errors.email">
				<form-input-text
					id="email"
					v-model="accountForm.fields.email"
					type="email"
					required
					autocomplete="username"
				/>
			</form-input-wrapper>

			<template v-if="user?.type === 'rider'">
				<div v-for="organisation in organisations" :key="organisation" class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
					<label class="block text-sm font-medium leading-6 text-gray-900 sm:pt-2">{{ t(`app.organisation.${organisation}`) }}</label>
					<div class="mt-2 sm:col-span-2 sm:mt-0">
						<div class="w-full px-3 py-2.5 text-sm">
							<template v-if="getRiderInfo(organisation)">
								{{ getRiderInfo(organisation) }}
							</template>
							<template v-else>
								<button-base :href="route('account.create-rider', { organisation })" variant="link">
									{{ t('app.add_your_organisation_account', { organisation: t('app.organisation.' + organisation) }) }}
								</button-base>
							</template>
						</div>
					</div>
				</div>
			</template>

			<button-base type="submit" :loading="accountForm.processing" class="ml-auto">
				{{ t('app.update') }}
			</button-base>
		</form>

		<heading3 class="mb-4">
			{{ t('app.update_password') }}
		</heading3>

		<message-wrapper>
			<message-success v-if="status === 'password-updated'">
				{{ t('app.update_success') }}
			</message-success>
		</message-wrapper>

		<form class="flex flex-col space-y-5" @submit.prevent="updatePasswordForm.submit">
			<form-input-wrapper :label="t('app.current_password')" for="current_password" :errors="updatePasswordForm.errors.current_password">
				<form-input-text
					id="current_password"
					v-model="updatePasswordForm.fields.current_password"
					type="password"
					required
					autocomplete="current-password"
				/>
			</form-input-wrapper>

			<form-input-wrapper :label="t('app.password')" for="password" :errors="updatePasswordForm.errors.password">
				<form-input-text
					id="password"
					v-model="updatePasswordForm.fields.password"
					type="password"
					required
					autocomplete="new-password"
				/>
			</form-input-wrapper>

			<form-input-wrapper :label="t('app.password_confirmation')" for="password_confirmation" :errors="updatePasswordForm.errors.password_confirmation">
				<form-input-text
					id="password_confirmation"
					v-model="updatePasswordForm.fields.password_confirmation"
					type="password"
					required
					autocomplete="new-password"
				/>
			</form-input-wrapper>

			<button-base type="submit" :loading="updatePasswordForm.processing" class="ml-auto">
				{{ t('app.update') }}
			</button-base>
		</form>
	</page-container>
</template>
