<script setup lang="ts">
defineProps<{
	events: {
		open: App.Data.EventData[]
		upcoming: App.Data.EventData[]
	}
}>()
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<page-container>
		<div class="space-y-10">
			<div class="space-y-4">
				<div class="rounded-xl bg-primary px-4 py-2">
					<heading3 class="text-white">
						{{ t('app.events_open') }}
					</heading3>
				</div>
				<event-list :events="events.open" />
			</div>

			<div class="space-y-4">
				<div class="rounded-xl bg-gray-800 px-4 py-2">
					<heading3 class="text-white">
						{{ t('app.events_upcoming') }}
					</heading3>
				</div>
				<event-list :events="events.upcoming" />
			</div>
		</div>
	</page-container>
</template>
