export function useInputMasks() {
	const organisationIdMaskOptions = reactive({
		mask: '###########',
		eager: true,
	})

	const birthdateMaskOptions = reactive({
		mask: '39/19/2999',
		eager: true,
		tokens: {
			3: {
				pattern: /[0-3]/,
			},
			9: {
				pattern: /[0-9]/,
			},
			1: {
				pattern: /[0-1]/,
			},
			2: {
				pattern: /[1-2]/,
			},
		},
	})

	const transponderMaskOptions = reactive({
		mask: 'AA-#####',
		eager: true,
		tokens: {
			A: {
				pattern: /[A-Z]/,
				transform: (chr: string) => chr.toUpperCase(),
			},
		},
	})

	return {
		organisationIdMaskOptions,
		birthdateMaskOptions,
		transponderMaskOptions,
	}
}
