<script setup lang="ts">
defineProps<{ event: App.Data.EventData }>()

const { user } = useUser()
</script>

<template>
	<li class="col-span-1 flex flex-col justify-between rounded-lg border border-gray-200">
		<div class="flex w-full grow justify-between space-x-6 p-6">
			<div class="flex flex-1 flex-col">
				<div class="mb-2 flex items-center space-x-3">
					<h3 class="font-semibold">
						<router-link :href="route('events.show', { event: event.uuid })">
							{{ event.name }}
						</router-link>
					</h3>
				</div>
				<p class="mt-auto flex flex-col text-sm text-gray-500">
					<span v-if="event.event_date" class="flex items-center gap-2">
						<i-gg-calendar-today class="h-4 w-4 shrink-0 text-primary" />
						{{ event.event_date }}
					</span>
					<span v-if="event.country" class="flex items-center gap-2">
						<i-gg-pin class="h-4 w-4 shrink-0 text-primary" />
						<template v-if="event.city">
							{{ event.city }}, {{ event.country }}
						</template>
						<template v-else>
							{{ event.country }}
						</template>
					</span>
					<span v-if="event.organizer.name" class="flex items-center gap-2">
						<i-gg-trophy class="h-4 w-4 shrink-0 text-primary" />
						{{ event.organizer.name }}
					</span>
				</p>
			</div>
			<img v-if="event.logo_url" class="max-h-20 max-w-20 shrink-0 bg-white" :src="event.logo_url" :alt="event.name" />
		</div>
		<div class="border-t border-gray-200">
			<div class="-mt-px flex divide-x divide-gray-200">
				<template v-if="!user || user?.type === 'rider' || (user?.type === 'entity' && !event.use_member_registrations)">
					<router-link v-if="event.show_registration_button && (event.registration_open || event.registration_late_entry_open || event.registration_extra_late_entry_open)" :href="route('events.registrations.index', { event: event.uuid })" class="-ml-px flex w-0 flex-1 items-center justify-center gap-2 py-4 text-sm font-semibold hover:text-primary">
						<i-ph-note-pencil-bold class="h-4 w-4 text-primary" />
						{{ t('app.sign_up') }}
					</router-link>
					<router-link v-if="event.show_registration_button && event.cancellation_open" :href="route('events.registrations.index', { event: event.uuid })" class="-ml-px flex w-0 flex-1 items-center justify-center gap-2 py-4 text-sm font-semibold hover:text-primary">
						<i-ph-trash-bold class="h-4 w-4 text-primary" />
						{{ t('app.cancel') }}
					</router-link>
				</template>
				<router-link v-else :href="route('member-history.show', { event: event.uuid })" class="-ml-px flex w-0 flex-1 items-center justify-center gap-2 py-4 text-sm font-semibold hover:text-primary">
					<i-ph-note-pencil-bold class="h-4 w-4 text-primary" />
					{{ t('app.registrations_title') }}
				</router-link>
				<router-link v-if="event.authorization.showEntries" :href="route('events.entries', { event: event.uuid })" class="-ml-px flex w-0 flex-1 items-center justify-center gap-2 py-4 text-sm font-semibold hover:text-primary">
					<i-gg-user-list class="h-5 w-5 text-primary" />
					{{ t('app.entries') }}
				</router-link>
				<router-link :href="route('events.show', { event: event.uuid })" class="-ml-px flex w-0 flex-1 items-center justify-center gap-2 py-4 text-sm font-semibold hover:text-primary">
					{{ t('app.info') }}
					<i-gg-chevron-double-right class="h-4 w-4 text-primary" />
				</router-link>
			</div>
		</div>
	</li>
</template>
