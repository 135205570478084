<script setup lang="ts">
import { c2c } from 'vue-c2c'
import InfoModal from '@/components/info-modal.vue'

const form = useForm({
	method: 'POST',
	url: route('login'),
	fields: {
		email: '',
		password: '',
		remember: true,
	},
})

const status = useProperty('status')

const useInfoModal = c2c(InfoModal)
const { hide, toggle, visible, exposed } = useInfoModal({
	title: t('app.request_entity_account'),
	content: '<ul><li>Send an email to info@jstiming.nl</li><li>Please include in this email:<ul><li>Your Club, Team or Federation name</li><li>Your country name</li><li>The email address to use [maximum 1 account per email address and 1 email address per account].</li></ul></li><li>You will receive a Verify email at the email address you entered</li><li>Please note that this is a \'No-reply\' email that occasionally ends up in your spam box</li><li>Click on \'Verify your email address\' to log in to your account</li><li>On the \'Riders\' page you can add your riders using \'Add rider\'</li></ul>',
	closeText: t('app.close'),
}, {
	emits: {
		// @ts-ignore-next-line
		onClose() {
			hide()
		},
	},
})
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/auth.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<message-wrapper>
		<message-success v-if="status" class="mb-2">
			{{ status }}
		</message-success>
	</message-wrapper>

	<form class="space-y-5" @submit.prevent="form.submit">
		<form-input-wrapper :label="t('app.email')" for="email" :errors="form.errors.email">
			<form-input-text
				id="email"
				v-model="form.fields.email"
				type="email"
				required
				autofocus
				autocomplete="username"
			/>
		</form-input-wrapper>

		<form-input-wrapper :label="t('app.password')" for="password" :errors="form.errors.password">
			<form-input-text
				id="password"
				v-model="form.fields.password"
				type="password"
				required
				autocomplete="current-password"
			/>
		</form-input-wrapper>

		<div
			class="flex items-center justify-between"
		>
			<div class="flex items-center space-x-2">
				<form-input-checkbox id="remember" v-model="form.fields.remember" />

				<label class="text-sm" for="remember">
					{{ t('app.remember_me') }}
				</label>
			</div>

			<button-base variant="link" :href="route('password.request')" class="text-sm">
				{{ t('app.forgot_your_password') }}
			</button-base>
		</div>

		<div class="flex">
			<button-base type="submit" :loading="form.processing" class="ml-auto">
				{{ t('app.login') }}
			</button-base>
		</div>

		<div class="relative">
			<div class="absolute inset-0 flex items-center">
				<div class="w-full border-t border-gray-300" />
			</div>
			<div class="relative flex justify-center text-sm">
				<span class="bg-white px-2 text-gray-500">{{ t('app.no_account_yet') }}</span>
			</div>
		</div>

		<div class="flex flex-col items-center justify-center space-y-4">
			<button-base :href="route('register')" variant="secondary">
				{{ t('app.register_your_account') }}
			</button-base>
			<button-base variant="link" type="button" @click="toggle">
				{{ t('app.request_entity_account') }}
			</button-base>
			<!--			<p>{{ t('app.request_club_or_team_account') }}</p>-->
		</div>
	</form>
</template>
