<script setup lang="ts">
const form = useForm({
	method: 'POST',
	url: route('password.confirm'),
	fields: {
		password: '',
	},
})

const status = useProperty('status')
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/auth.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<message-wrapper>
		<message-success v-if="status" class="mb-2">
			{{ status }}
		</message-success>
	</message-wrapper>

	<form class="space-y-5" @submit.prevent="form.submit">
		<form-input-wrapper :label="t('app.password')" for="password" :errors="form.errors.password">
			<form-input-text
				id="password"
				v-model="form.fields.password"
				type="password"
				required
				autocomplete="current-password"
				autofocus
			/>
		</form-input-wrapper>

		<div class="flex">
			<button-base type="submit" :loading="form.processing" class="ml-auto">
				{{ t('app.confirm_password') }}
			</button-base>
		</div>
	</form>
</template>
