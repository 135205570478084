import { RouteName } from 'hybridly'

export function useMenu() {
	const [menuVisible, menuToggle] = useToggle()
	const { user } = useUser()

	const menuItems = ref([
		{
			route: 'organizers.index' as RouteName,
			name: t('app.organizers.index.menu_title'),
		},
	])

	if (user.value) {
		menuItems.value.push({
			route: 'history.index' as RouteName,
			name: t('app.history.index.title'),
		})

		if (
			user.value?.type === 'entity'
			&& !user.value.entity?.hide_member_registrations
		) {
			menuItems.value.push({
				route: 'member-history.index' as RouteName,
				name: t('app.member-history.index.title'),
			})
		}

		menuItems.value.push({
			route: 'riders.index' as RouteName,
			name: t('app.riders.index.title'),
		})
	}

	menuItems.value.push({
		route: 'faq.index' as RouteName,
		name: t('app.faq.index.title'),
	})

	watch(user, () => {
		if (!user.value) {
			menuItems.value = menuItems.value.filter((item) => item.route === 'faq.index' || item.route === 'organizers.index')
		}
	})

	registerHook('navigated', () => {
		menuToggle(false)
	})

	return {
		menuItems: readonly(menuItems),
		menuVisible: readonly(menuVisible),
		menuToggle,
	}
}
