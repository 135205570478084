import { initializeHybridly } from 'virtual:hybridly/config'
import { createHead } from '@unhead/vue'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { vMaska } from 'maska'
import i18n from './i18n'
import './fonts'
import './tailwind.css'
import sentryVuePlugin from '@/plugins/vue-sentry-plugin'

initializeHybridly({
	enhanceVue: (vue) => {
		const head = createHead()
		head.push({ titleTemplate: (title) => title ? `${title} - Registration JSTiming` : 'Registration JSTiming' })
		vue.use(head)

		vue.use(sentryVuePlugin)
		vue.use(autoAnimatePlugin)
		vue.use(i18n)
		vue.directive('maska', vMaska)
	},
	viewTransition: false,
	progress: {
		color: '#ff3d3d',
	},
})
