<script setup lang="ts">
const $props = defineProps<{
	registrationLine: App.Data.Registration.RegistrationLineData
	categories: App.Data.EventCategoryData[]
}>()

const { closeLocally } = useDialog()

const form = useForm<App.Data.Registration.UpdateRegistrationLineData>({
	method: 'PATCH',
	url: route('registrationLines.update', { registrationLine: $props.registrationLine.uuid }),
	reset: false,
	fields: {
		event_category: $props.registrationLine.event_category,
	},
	hooks: {
		success: () => {
			closeLocally()
		},
	},
})
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<modal-base :title="t('app.edit_rider', { rider: registrationLine.full_name })">
		<form class="space-y-5" @submit.prevent="form.submit">
			<form-input-wrapper :label="t('app.category')" for="category" :errors="form.errors.event_category">
				<form-select id="category" v-model="form.fields.event_category">
					<option value="">
						{{ t('app.select_category') }}
					</option>
					<option v-for="category in categories" :key="category.name">
						{{ category.name }}
					</option>
				</form-select>
			</form-input-wrapper>

			<div class="flex justify-end space-x-4">
				<button-base variant="secondary" type="button" @click="closeLocally">
					{{ t('app.cancel') }}
				</button-base>

				<button-base type="submit" :loading="form.processing">
					{{ t('app.save') }}
				</button-base>
			</div>
		</form>
	</modal-base>
</template>
