<script setup lang="ts">
const props = defineProps<{
	modelValue: string | number | null
}>()

const emit = defineEmits<{
	(e: 'update:modelValue', value: string | number): void
}>()

const value = useVModel(props, 'modelValue', emit)
</script>

<template>
	<select
		v-model="value"
		class="w-full border-gray-300 px-3 py-2.5 text-sm transition focus:border-gray-700 focus:ring-0 disabled:cursor-not-allowed"
	>
		<slot />
	</select>
</template>
