<script setup lang="ts">
const context = useContext()
const component = ref<string>('')

onMounted(() => {
	component.value = context.value?.view.component || ''
})

registerHook('navigated', () => {
	component.value = context.value?.view.component || ''
})

const { pageTitle } = usePageTitle()
useHead({
	title: pageTitle,
})
</script>

<template>
	<div class="bg-gray-50">
		<main class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div class="flex flex-col items-center space-y-6 sm:mx-auto sm:w-full sm:max-w-md">
				<router-link :href="route('login')">
					<i-jstiming-logo class="h-[50px] w-[200px]" />
				</router-link>

				<heading-1>
					{{ pageTitle }}
				</heading-1>
			</div>

			<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl">
				<div class="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
					<slot />
				</div>
			</div>

			<div
				v-if="!['auth.login', 'auth.verify-email', 'auth.confirm-password', 'auth.unattached'].includes(component)"
				class="mt-4 text-center sm:mx-auto sm:w-full sm:max-w-md"
			>
				<router-link :href="route('login')" class="text-gray-500 hover:text-black hover:underline">
					{{ t('app.back_to_login') }}
				</router-link>
			</div>
		</main>
	</div>
</template>
