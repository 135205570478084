<script setup lang="ts">
defineProps<{
	faqCategories: App.Data.FaqCategoryData[]
}>()
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<page-container>
		<div class="flex flex-col gap-4">
			<template v-for="category in faqCategories" :key="category.uuid">
				<h3 class="rounded-xl bg-gray-800 px-4 py-2 font-open-sans text-xl font-bold tracking-tight text-white">
					{{ category.name }}
				</h3>

				<details v-for="faq in category.faq_items" :key="faq.uuid">
					<summary class="cursor-pointer rounded-xl bg-gray-100 p-2 font-bold tracking-tight text-gray-800 hover:bg-gray-200">
						<span class="pl-1">{{ faq.name }}</span>
					</summary>
					<div class="prose mt-2 max-w-none text-sm [&_figcaption>span]:hidden md:[&_img]:w-[70%]" v-html="faq.description" />
				</details>
			</template>
		</div>
	</page-container>
</template>
