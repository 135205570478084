<script setup lang="ts">
const $props = defineProps<{
	organisations: App.Enums.OrganisationEnum[]
}>()

const { organisationIdMaskOptions, birthdateMaskOptions, transponderMaskOptions } = useInputMasks()
const form = useForm({
	method: 'POST',
	url: route('register'),
	fields: {
		organisation: 'uci',
		organisation_id: '',
		birthdate: '',
		email: '',
		email_confirmation: '',
		password: '',
		password_confirmation: '',
		mylaps_transponder_20inch: '',
		mylaps_transponder_cruiser: '',
	},
})

const banner = useProperty('applicationBanner')
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/auth.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<message-wrapper>
		<message-error v-if="banner" class="mb-2">
			{{ banner }}
		</message-error>
	</message-wrapper>

	<form class="flex flex-col space-y-5" @submit.prevent="form.submit">
		<form-input-wrapper v-if="organisations.length > 1" :label="t('app.organisation.fallback')" for="organisation" :errors="form.errors.organisation">
			<form-select
				id="organisation"
				v-model="form.fields.organisation"
				required
			>
				<option v-for="organisation in $props.organisations" :key="organisation" :value="organisation">
					{{ t(`app.organisation.${organisation}`) }}
				</option>
			</form-select>
		</form-input-wrapper>

		<form-input-wrapper :label="form.fields.organisation ? t(`app.organisation_id.${form.fields.organisation}`) : t('app.organisation_id.fallback')" for="organisation_id" :errors="form.errors.organisation_id">
			<form-input-text
				id="organisation_id"
				v-model="form.fields.organisation_id"
				v-maska:[organisationIdMaskOptions]
				required
				autofocus
			/>
		</form-input-wrapper>

		<form-input-wrapper :label="t('app.birthdate')" for="birthdate" :errors="form.errors.birthdate">
			<form-input-text
				id="birthdate"
				v-model="form.fields.birthdate"
				v-maska:[birthdateMaskOptions]
				placeholder="dd/mm/yyyy"
				required
			/>
		</form-input-wrapper>

		<form-input-wrapper :label="t('app.email')" for="email" :errors="form.errors.email">
			<form-input-text
				id="email"
				v-model="form.fields.email"
				type="email"
				required
				autocomplete="username"
			/>
		</form-input-wrapper>

		<form-input-wrapper :label="t('app.email_confirmation')" for="email_confirmation" :errors="form.errors.email_confirmation">
			<form-input-text
				id="email_confirmation"
				v-model="form.fields.email_confirmation"
				type="email"
				required
			/>
		</form-input-wrapper>

		<form-input-wrapper :label="t('app.password')" for="password" :errors="form.errors.password">
			<form-input-text
				id="password"
				v-model="form.fields.password"
				type="password"
				required
				autocomplete="new-password"
			/>
		</form-input-wrapper>

		<form-input-wrapper :label="t('app.password_confirmation')" for="password_confirmation" :errors="form.errors.password_confirmation">
			<form-input-text
				id="password_confirmation"
				v-model="form.fields.password_confirmation"
				type="password"
				required
				autocomplete="new-password"
			/>
		</form-input-wrapper>

		<div class="relative">
			<div class="absolute inset-0 flex items-center">
				<div class="w-full border-t border-gray-300" />
			</div>
			<div class="relative flex justify-center text-sm">
				<span class="bg-white px-2 text-gray-500">{{ t('app.mylaps_transponders') }}</span>
			</div>
		</div>

		<form-input-wrapper :label="t('app.20inch')" for="mylaps_transponder_20inch" :errors="form.errors.mylaps_transponder_20inch" optional>
			<form-input-text
				id="mylaps_transponder_20inch"
				v-model="form.fields.mylaps_transponder_20inch"
				v-maska:[transponderMaskOptions]
			/>
		</form-input-wrapper>

		<form-input-wrapper :label="t('app.cruiser')" for="mylaps_transponder_cruiser" :errors="form.errors.mylaps_transponder_cruiser" optional>
			<form-input-text
				id="mylaps_transponder_cruiser"
				v-model="form.fields.mylaps_transponder_cruiser"
				v-maska:[transponderMaskOptions]
			/>
		</form-input-wrapper>

		<button-base type="submit" :loading="form.processing" class="ml-auto">
			{{ t('app.register') }}
		</button-base>
	</form>
</template>
