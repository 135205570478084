<script setup lang="ts">
const slots = useSlots()
</script>
<template>
	<div class="overflow-x-auto">
		<div class="inline-block min-w-full py-2 align-middle">
			<table class="min-w-full divide-y divide-gray-300">
				<thead v-if="slots.head">
					<slot name="head" />
				</thead>
				<tbody class="divide-y divide-gray-200">
					<slot />
				</tbody>
			</table>
		</div>
	</div>
</template>
