<script setup lang="ts">
import {
	NotificationRegion,
	setupNotifications,
} from '@/composables/useNotification'

const $props = defineProps<{
	pageTitle?: string
	pageTitleLink?: string
}>()

const { menuItems, menuVisible, menuToggle } = useMenu()
const status = useProperty('status')
const pageTitle = computed(() => $props.pageTitle || usePageTitle().pageTitle.value)
const { user } = useUser()

useHead({
	title: pageTitle,
})

setupNotifications()
</script>

<template>
	<div class="bg-gray-800 pb-32">
		<nav class="bg-gray-800">
			<div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
				<div class="border-b border-gray-700">
					<div class="flex h-16 items-center justify-between px-4 sm:px-0">
						<div class="flex items-center">
							<div class="shrink-0">
								<router-link :href="route('organizers.index')">
									<i-jstiming-logo-white class="h-[25px] w-[100px]" />
								</router-link>
							</div>
							<div class="hidden md:block">
								<div class="ml-10 flex items-baseline space-x-4">
									<router-link v-for="menuItem in menuItems" :key="'desktop_' + menuItem.route" :class="{ 'rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white': true }" :href="route(menuItem.route)">
										{{ menuItem.name }}
									</router-link>
								</div>
							</div>
						</div>
						<div class="hidden md:block">
							<div class="ml-4 flex items-center space-x-8 md:ml-6">
								<template v-if="user">
									<router-link :href="route('account.index')" class="text-sm font-medium text-gray-300 hover:text-white">
										{{ t('app.my_account') }}
									</router-link>

									<router-link :href="route('logout')" method="POST" class="text-sm font-medium text-gray-300 hover:text-white">
										{{ t('app.logout') }}
									</router-link>
								</template>
								<template v-else>
									<router-link :href="route('register')" class="text-sm font-medium text-gray-300 hover:text-white">
										{{ t('app.register') }}
									</router-link>

									<router-link :href="route('login')" class="text-sm font-medium text-gray-300 hover:text-white">
										{{ t('app.login') }}
									</router-link>
								</template>
							</div>
						</div>
						<div class="-mr-2 flex md:hidden">
							<button type="button" class="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" aria-controls="mobile-menu" aria-expanded="false" @click="menuToggle()">
								<i-gg-menu v-show="! menuVisible" class="block h-6 w-6" />
								<i-gg-close v-show="menuVisible" class="block h-6 w-6" />
							</button>
						</div>
					</div>
				</div>
			</div>

			<div v-show="menuVisible" class="border-b border-gray-700 md:hidden">
				<div class="space-y-1 px-2 py-3 sm:px-3">
					<router-link v-for="menuItem in menuItems" :key="'desktop_' + menuItem.route" :class="{ 'block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white': true }" :href="route(menuItem.route)">
						{{ menuItem.name }}
					</router-link>
				</div>
				<div class="border-t border-gray-700 pb-3 pt-4">
					<div class="mt-3 space-y-1 px-2">
						<template v-if="user">
							<router-link :href="route('account.index')" class="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
								{{ t('app.my_account') }}
							</router-link>

							<router-link :href="route('logout')" method="POST" class="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
								{{ t('app.logout') }}
							</router-link>
						</template>
						<template v-else>
							<router-link :href="route('register')" class="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
								{{ t('app.register') }}
							</router-link>

							<router-link :href="route('login')" class="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white">
								{{ t('app.login') }}
							</router-link>
						</template>
					</div>
				</div>
			</div>
		</nav>
		<header class="py-10">
			<div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<heading1 class="text-white">
					<router-link v-if="pageTitleLink" :href="pageTitleLink">
						{{ pageTitle }}
					</router-link>
					<template v-else>
						{{ pageTitle }}
					</template>
				</heading1>

				<message-wrapper>
					<message-success v-if="status === 'welcome'" class="mt-4">
						{{ t('app.welcome') }}
					</message-success>
				</message-wrapper>
			</div>
		</header>
	</div>

	<main class="-mt-32">
		<div class="mx-auto max-w-7xl pb-12 md:px-6 lg:px-8">
			<slot />
		</div>
	</main>

	<notification-region />
</template>
