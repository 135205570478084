<script setup lang="ts">
const $props = defineProps<{
	organisation: App.Enums.OrganisationEnum
	organisations: App.Enums.OrganisationEnum[]
}>()

const { organisationIdMaskOptions, birthdateMaskOptions, transponderMaskOptions } = useInputMasks()
const { closeLocally } = useDialog()

const form = useForm<App.Data.Rider.CreateRiderData>({
	method: 'POST',
	url: route('riders.store'),
	fields: {
		organisation: $props.organisation,
		organisation_id: '',
		birthdate: '',
		mylaps_transponder_20inch: '',
		mylaps_transponder_cruiser: '',
	},
	hooks: {
		success: () => {
			closeLocally()
		},
	},
})
</script>

					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<modal-base :title="t('app.add_rider')">
		<form class="space-y-5" @submit.prevent="form.submit">
			<form-input-wrapper v-if="organisations.length > 1" :label="t('app.organisation.fallback')" for="organisation" :errors="form.errors.organisation">
				<form-select
					id="organisation"
					v-model="form.fields.organisation"
					required
					disabled
				>
					<option v-for="organisationOption in $props.organisations" :key="organisationOption" :value="organisationOption">
						{{ t(`app.organisation.${organisationOption}`) }}
					</option>
				</form-select>
			</form-input-wrapper>

			<form-input-wrapper :label="form.fields.organisation ? t(`app.organisation_id.${form.fields.organisation}`) : t('app.organisation_id.fallback')" for="organisation_id" :errors="form.errors.organisation_id">
				<form-input-text
					id="organisation_id"
					v-model="form.fields.organisation_id"
					v-maska:[organisationIdMaskOptions]
					required
					autofocus
				/>
			</form-input-wrapper>

			<form-input-wrapper :label="t('app.birthdate')" for="birthdate" :errors="form.errors.birthdate">
				<form-input-text
					id="birthdate"
					v-model="form.fields.birthdate"
					v-maska:[birthdateMaskOptions]
					placeholder="dd/mm/yyyy"
					required
				/>
			</form-input-wrapper>

			<form-input-wrapper :label="t('app.mylaps_transponder_20inch')" for="mylaps_transponder_20inch" :errors="form.errors.mylaps_transponder_20inch" optional>
				<form-input-text
					id="mylaps_transponder_20inch"
					v-model="form.fields.mylaps_transponder_20inch"
					v-maska:[transponderMaskOptions]
				/>
			</form-input-wrapper>

			<form-input-wrapper :label="t('app.mylaps_transponder_cruiser')" for="mylaps_transponder_cruiser" :errors="form.errors.mylaps_transponder_cruiser" optional>
				<form-input-text
					id="mylaps_transponder_cruiser"
					v-model="form.fields.mylaps_transponder_cruiser"
					v-maska:[transponderMaskOptions]
				/>
			</form-input-wrapper>

			<div class="flex justify-end space-x-4">
				<button-base variant="secondary" type="button" @click="closeLocally">
					{{ t('app.cancel') }}
				</button-base>

				<button-base type="submit" :loading="form.processing">
					{{ t('app.save') }}
				</button-base>
			</div>
		</form>
	</modal-base>
</template>
