<script setup lang="ts">
const props = defineProps<{
	modelValue: boolean
}>()

const emit = defineEmits<{
	(e: 'update:modelValue', value: boolean): void
}>()

const value = useVModel(props, 'modelValue', emit)
</script>

<template>
	<input
		v-model="value"
		type="checkbox"
		class="h-4 w-4 rounded border-2 border-gray-900 text-primary focus:ring-primary"
	/>
</template>
