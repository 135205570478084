import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'
import { Plugin } from 'vue'

const sentryVuePlugin: Plugin = {
	install: (app) => {
		if (
			import.meta.env.PROD
			&& import.meta.env.VITE_SENTRY_DSN_PUBLIC
			&& import.meta.env.VITE_SENTRY_DSN_PUBLIC !== 'null'
		) {
			Sentry.init({
				app,
				dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
				logErrors: true,
				trackComponents: true,
				integrations: [new BrowserTracing()],
			})
		}
	},
}

export default sentryVuePlugin
