<script setup lang="ts">
import { c2c } from 'vue-c2c'
import Confirm from '@/components/confirm.vue'

const $props = defineProps<{
	filters: App.Data.Rider.SearchRiderData
	riders: Paginator<App.Data.Rider.RiderData>
	organisations: App.Enums.OrganisationEnum[]
}>()

const { user } = useUser()
const searchForm = ref($props.filters)
const riderAccountMaximumAttachedRiders = useProperty('riderAccountMaximumAttachedRiders')
const search = () => {
	router.get(route('riders.index'), {
		data: searchForm.value,
		preserveState: true,
	})
}

const detachRider = ref<App.Data.Rider.RiderData | null>(null)
const useConfirm = c2c(Confirm)
const { hide, toggle, visible, exposed } = useConfirm({
	title: t('app.detach'),
	content: t('app.rider_detach_confirm'),
	confirmText: t('app.yes'),
	cancelText: t('app.no'),
}, {
	emits: {
		onOk() {
			if (!detachRider.value) {
				return
			}

			router.delete(route('riders.destroy', { rider: detachRider.value.uuid }), {
				preserveState: true,
				preserveScroll: true,
				only: ['riders'],
			})

			detachRider.value = null
		},
		// @ts-ignore-next-line
		onCancel() {
			detachRider.value = null
		},
	},
})
watch(detachRider, (newValue) => {
	if (newValue) {
		toggle()
	} else {
		hide()
	}
})

watch(() => searchForm.value.keyword, () => {
	if (searchForm.value.keyword === '') {
		router.get(route('riders.index'), {
			data: searchForm.value,
			preserveState: true,
		})
	}
}, { deep: true })

watch(() => searchForm.value.organisation, () => {
	router.get(route('riders.index'), {
		data: searchForm.value,
		preserveState: true,
	})
}, { deep: true })
</script>

					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<page-container>
		<div v-if="organisations.length > 1" class="mb-4 flex items-center gap-4">
			<span class="text-gray-600">{{ t('app.organisation.fallback') }}:</span>
			<template v-for="organisation in organisations" :key="organisation">
				<label class="flex items-center gap-2 bg-gray-200 px-4 py-2 hover:bg-gray-300">
					<input v-model="searchForm.organisation" type="radio" :value="organisation" />
					{{ t(`app.organisation.${organisation}`) }}
				</label>
			</template>
		</div>

		<div class="justify-between space-y-4 md:flex md:space-y-0">
			<template v-if="filters.organisation !== 'nff' || user?.type === 'rider'">
				<div class="flex space-x-4">
					<button-base :href="route('riders.create', { organisation: searchForm.organisation })">
						{{ t('app.add_rider') }}
					</button-base>

					<button-base v-if="user?.type === 'entity'" :href="route('riders.bulk-create', { organisation: searchForm.organisation })">
						<i-gg-import class="h-4 w-4" aria-hidden="true" />
						{{ t('app.import_riders') }}
					</button-base>
				</div>
			</template>

			<form v-if="user?.type === 'entity'" class="flex md:w-1/3" @submit.prevent>
				<form-input-text v-model="searchForm.keyword" :placeholder="t('app.search_for_a_rider')" type="search" />
				<button-base @click="search">
					{{ t('app.search') }}
				</button-base>
			</form>
		</div>

		<div v-if="riders.data.length" class="space-y-4">
			<table-base>
				<template #head>
					<table-row>
						<table-head>{{ filters.organisation ? t(`app.organisation_id.${filters.organisation}`) : t('app.organisation_id.fallback') }}</table-head>
						<table-head>{{ t('app.name') }}</table-head>
						<table-head>{{ t('app.birthdate') }}</table-head>
						<table-head>{{ t('app.mylaps_transponder_20inch') }}</table-head>
						<table-head>{{ t('app.mylaps_transponder_cruiser') }}</table-head>
						<table-head />
					</table-row>
				</template>
				<table-row v-for="rider in riders.data" :key="rider.uuid">
					<table-column>
						<span class="flex items-center gap-1">
							{{ rider.organisation_id }}
							<span v-if="rider.status === 'inactive'" class="flex items-center gap-1 rounded-full bg-primary p-1 text-xs text-white">
								<i-gg-danger class="size-4" />
								{{ t('app.no_license') }}
							</span>
						</span>
					</table-column>
					<table-column>{{ rider.full_name }}</table-column>
					<table-column>{{ rider.birthdate }}</table-column>
					<table-column>{{ rider.mylaps_transponder_20inch }}</table-column>
					<table-column>{{ rider.mylaps_transponder_cruiser }}</table-column>
					<table-column>
						<template v-if="rider.user_bound">
							<button-base variant="link" :href="route('riders.edit', { rider: rider.uuid })">
								{{ t('app.edit') }}
							</button-base>
						</template>
						<template v-else>
							<button-base variant="link" :href="route('riders.edit', { rider: rider.uuid })">
								{{ t('app.edit') }}
							</button-base>
							<span class="px-2 text-gray-400">|</span>
							<button-base variant="link" @click="detachRider = rider">
								{{ t('app.detach') }}
							</button-base>
						</template>
					</table-column>
				</table-row>
			</table-base>

			<p v-if="user?.type === 'rider'" class="text-center text-sm text-gray-600">
				{{ t('app.total_riders_found_of_maximum', { total: riders.meta.total, maximum: riderAccountMaximumAttachedRiders }) }}
			</p>
			<p v-else class="text-center text-sm text-gray-600">
				{{ t('app.total_riders_found', { total: riders.meta.total }) }}
			</p>

			<pagination :links="riders.links" />
		</div>
		<p v-else class="mt-4 text-gray-700">
			{{ t('app.no_riders_found') }}
		</p>
	</page-container>
</template>
